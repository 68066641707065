import React, { useState, useEffect, useContext } from 'react';
import {
  params, string, arrayOf,
  shape, useDataModel, extend
} from '@thd-nucleus/data-sources';
import PropTypes from 'prop-types';
import {
  ProductPod,
  ProductATC,
  ProductRating,
  ProductImage,
  PodFooter,
  PodSection,
  PodSpacer
} from '@thd-olt-component-react/product-pod';
import { Button } from '@one-thd/sui-atomic-components';
import { ProductQuantity } from '@thd-olt-component-react/quantity';
import { Price } from '@thd-olt-component-react/price';
import { ProductHeader } from '@thd-olt-component-react/product-details';
import { FulfillmentPodShipping, FulfillmentPodStore } from '@thd-olt-component-react/fulfillment';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';

export const AddToProductPod = ({
  itemId,
  index,
  parent,
  storeId,
  onClick,
  isMobile,
  groupsExist,
  updateList,
}) => {
  const [productSelection, setProductSelection] = useState({ itemId: null, quantity: null });
  const [nextScreenName, setNextScreenName] = useState(null);
  const {
    setScreen, setSelectedProductDetails, products, addToType, screen, setShowSuccessMessage, setShowErrorMessage
  } = useContext(AddToContext);
  const analyticsData = {
    position: index,
    parent
  };
  const {
    PROJECT_SELECT_GROUP,
    PROJECT_CREATE_GROUP,
    MINI_PIP_BASE
  } = SCREENS;

  const { data: productData, error, loading } = useDataModel('product', {
    variables: {
      itemId,
    },
    skip: !itemId
  });

  const handleSelectedProductClick = (item, itemQuantity, screenName) => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setProductSelection({ itemId: item, quantity: itemQuantity });
    if (addToType === 'Project' && !groupsExist && screenName !== MINI_PIP_BASE) {
      setNextScreenName(PROJECT_CREATE_GROUP);
    } else if (addToType === 'Project' && groupsExist && screenName !== MINI_PIP_BASE) {
      setNextScreenName(PROJECT_SELECT_GROUP);
    } else if (addToType === 'List' && screenName !== MINI_PIP_BASE) {
      updateList({
        variables: {
          products: [{ itemId: item, quantity: itemQuantity }],
        }
      });
    } else {
      setNextScreenName(screenName);
    }
  };

  useEffect(() => {
    if (productData?.product?.itemId && nextScreenName && productSelection) {
      setSelectedProductDetails({ itemId: productSelection.itemId, quantity: productSelection.quantity, productData });
      setScreen(nextScreenName);
    }
  }, [productData, productSelection]);

  return (
    <ProductPod
      itemId={itemId}
      analyticsData={analyticsData}
      render={(pod) => {
        return (
          <>
            <PodSection columnAlign alignTop>
              {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
              <div
                className="sui-cursor-pointer"
                onClick={() => handleSelectedProductClick(itemId, 1, MINI_PIP_BASE)}
                data-testid="mini-pip-nav-container"
              >
                <div className="sui-pointer-events-none">
                  <ProductImage itemId={pod.itemId} target="_blank" />
                  <ProductHeader
                    brand="above"
                    itemId={pod.itemId}
                    brandTitleMaxLine={4}
                    disableShopThisCollection
                    onClick={onClick}
                    target="_blank"
                  />
                  <PodSpacer disable={isMobile}>
                    <ProductRating itemId={pod.itemId} onClick={onClick} target="_blank" />
                  </PodSpacer>
                  <PodSpacer minHeight="3.75rem" padding={['TOP']} disable={isMobile}>
                    <Price
                      itemId={pod.itemId}
                      large={false}
                      storeId={storeId}
                      displayEachUom={false}
                      showPreferredPricingBadge
                      hideForProductTypes={['HDQC']}
                    />
                  </PodSpacer>
                </div>
              </div>
            </PodSection>
            <PodFooter>
              <PodSpacer minHeight="11rem">
                <div className="sui-pointer-events-none">
                  <FulfillmentPodStore itemId={pod.itemId} storeId={storeId} hideForChooseYourOptions />
                  <FulfillmentPodShipping itemId={pod.itemId} storeId={storeId} />
                </div>
              </PodSpacer>
              <PodSpacer minHeight="5rem" padding={['TOP', 'BOTTOM']}>
                <ProductQuantity
                  itemId={pod.itemId}
                  onQuantityChange={pod.onQuantityUpdate}
                  showQuantityBox
                  wideView
                  noRightMargin
                />
              </PodSpacer>
              <Button
                fullWidth
                onClick={() => handleSelectedProductClick(pod.itemId, pod.quantity)}
              >
                Add to {addToType}
              </Button>
            </PodFooter>
          </>
        );
      }}
    />
  );
};

AddToProductPod.propTypes = {
  itemId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  parent: PropTypes.string.isRequired,
  storeId: PropTypes.string,
  onClick: PropTypes.func,
  isMobile: PropTypes.bool,
  groupsExist: PropTypes.bool,
  updateList: PropTypes.func,
};

AddToProductPod.defaultProps = {
  storeId: '',
  onClick: () => { },
  isMobile: false,
  groupsExist: false,
  updateList: () => { },
};

AddToProductPod.dataModel = extend(
  {
    product: params({
      itemId: string()
        .isRequired(),
      dataSource: string()
    }).shape({
      itemId: string(),
      dataSources: string(),
      identifiers: shape({
        brandName: string(),
        productLabel: string(),
        storeSkuNumber: string(),
        omsThdSku: string(),
        specialOrderSku: string()
      }),
      media: shape({
        images: arrayOf(shape({
          url: string(),
          type: string(),
          subType: string()
        }))
      }),
    }),
  },
  ProductPod,
  ProductImage,
  ProductHeader,
  ProductRating,
  Price,
  ProductQuantity,
  ProductATC,
  FulfillmentPodStore,
  FulfillmentPodShipping
);
