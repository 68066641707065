import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  params, string, arrayOf,
  shape, useDataModel, extend
} from '@thd-nucleus/data-sources';
import {
  Button, DrawerBody,
  DrawerFooter, Typography,
  Skeleton, SkeletonLine,
  SkeletonBlock, RatingMeter, Link
} from '@one-thd/sui-atomic-components';
import { Carousel } from '@one-thd/sui-carousel';
import { ArrowForward } from '@one-thd/sui-icons';
import { useStore } from '@thd-nucleus/experience-context';
import { Price } from '@thd-olt-component-react/price';
import { AddToContext } from '../../AddToContext';
import { SCREENS } from '../../AddToConstants';
import { Quantity } from '@thd-olt-component-react/quantity';
import { SuperSku } from '@thd-olt-component-react/super-sku';
import { SalientPoints, SalientListItem } from '@thd-olt-component-react/salient-points';

export const MiniPIPBase = ({ itemId, updateList }) => {
  const store = useStore({ varnish: false, online: false });
  const { storeId } = store;
  const { setScreen, addToType, setSelectedProductDetails } = useContext(AddToContext);
  const {
    PROJECT_SELECT_GROUP,
    MINI_PIP_PRODUCT_OVERVIEW,
    MINI_PIP_PRODUCT_SPECS,
    MINI_PIP_PRODUCT_REVIEWS,
    DEFAULT
  } = SCREENS;

  const { data, loading } = useDataModel('product', {
    variables: {
      itemId
    },
    skip: !itemId
  });

  const { product } = data || {};
  const images = product?.media?.images || [];
  const brandName = product?.identifiers?.brandName || '';
  const productLabel = product?.identifiers?.productLabel || '';
  const averageRating = Number(product?.reviews?.ratingsReviews?.averageRating || 0);
  const totalRatings = String(product?.reviews?.ratingsReviews?.totalReviews || 0);
  const [quantity, setQuantity] = useState(1)
  const setImageDisplaySize = (url = '') => url.replace(/<SIZE>/g, '400');
  
  const onQuantityChange = (item, value) => {
    const quant = value?.quantity;
    setQuantity(quant)
  };

  const onChange = (changeData = {}) => {
    const { canonicalUrl, itemId } = changeData;
    if (canonicalUrl) {
      setSelectedProductDetails({ itemId: itemId, quantity: quantity, productData: product });
    }
  };

  const handleSubmit = async () => {
    if (addToType === 'List') {
      setSelectedProductDetails({ itemId: itemId, quantity: quantity, productData: product });
      await updateList({
        variables: {
          products: [{ itemId, quantity: quantity }],
        }
      });
      setScreen(DEFAULT);
    } else {
      setSelectedProductDetails({ itemId: itemId, quantity: quantity, productData: product });
      setScreen(PROJECT_SELECT_GROUP);
    }
  };

  const handleBttnClick = (event) => {
    const details = product.specificationGroup.find((group) => group.specTitle === 'Details');
    const applianceType = details.specifications.find((spec) => spec.specName === 'Appliance Type').specValue;
    window.LIFE_CYCLE_EVENT_BUS.trigger('quick-view.click-footer',
      {
        productType: product?.identifiers?.productType,
        isLaundryBundle: applianceType.includes('Washer')
          || applianceType.includes('Dryer')
          || applianceType.includes('Pedestal')
          || applianceType.includes('Part/Accessory')
      }
    );
  };

  if (loading) {
    return (
      <DrawerBody>
        <SkeletonLine />
        <Skeleton grow>
          <div className="sui-w-full sui-h-full sui-px-9">
            <SkeletonBlock width={384} height={384} />
          </div>
        </Skeleton>
        <SkeletonLine variant="multi" numberOfLines={4} />
      </DrawerBody>
    );
  }

  return (
    <>
      <DrawerBody>
        <div className="sui-flex sui-flex-col sui-gap-4">
          <Typography variant="h5" data-testid="test-brandName">{brandName}</Typography>
          <Typography data-testid="test-productLabel" >{productLabel}</Typography>
          <Carousel disableMargin disableShadow type="hero" className="flex">
            {images.map((image, index) => (
              <div key={`hero-image-${index}`}>
                <img
                  src={setImageDisplaySize(image.url)}
                  alt={productLabel}
                  width={384}
                  height={384}
                  title={productLabel}
                  loading="lazy"
                />
              </div>
            ))}
          </Carousel>
          <SalientPoints itemId={itemId}>
            <SalientListItem>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                underline="always"
                onClick={() => setScreen(MINI_PIP_PRODUCT_OVERVIEW)}
                data-testid="view-more"
                component="button"
              >
                View More Details
              </Link>
            </SalientListItem>
          </SalientPoints>
          <Price
            itemId={itemId}
            large={false}
            storeId={storeId}
            displayEachUom={false}
            showPreferredPricingBadge
            hideForProductTypes={['HDQC']}
          />
          <Quantity
            value={quantity}
            onChange={(event) => onQuantityChange(itemId, event)}
          />
          <SuperSku itemId={itemId} onChange={onChange} disableMediaAndAvailability />
        </div>
        <div className="sui-flex sui-flex-col sui-gap-2">
          {/* eslint-disable react/button-has-type */}
          <button
            className="sui-grow sui-flex sui-justify-between sui-px-1 sui-h-11 sui-items-center"
            onClick={() => setScreen(MINI_PIP_PRODUCT_OVERVIEW)}
            data-testid="product-overview-button"
          >
            <Typography variant="h6">Product Overview</Typography>
            <ArrowForward />
          </button>
          <button
            className="sui-grow sui-flex sui-justify-between sui-px-1 sui-h-11 sui-items-center"
            onClick={() => setScreen(MINI_PIP_PRODUCT_SPECS)}
            data-testid="product-specs-button"
          >
            <Typography variant="h6">Specs</Typography>
            <ArrowForward />
          </button>
          {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
          <div
            className="sui-grow sui-flex sui-justify-between sui-px-1 sui-h-11 sui-items-center sui-cursor-pointer"
            onClick={() => setScreen(MINI_PIP_PRODUCT_REVIEWS)}
            data-testid="product-reviews-button"
          >
            <div className="sui-flex sui-items-center">
              <Typography variant="h6">
                Reviews
              </Typography>
              <RatingMeter
                label={totalRatings}
                value={averageRating}
              />
            </div>
            <ArrowForward />
          </div>
        </div>
      </DrawerBody>
      <DrawerFooter position="center">
        <div className="sui-flex sui-flex-col sui-gap-2">
          <Button
            onClick={handleBttnClick}
            fullWidth
            tag="a"
            href={product?.identifiers?.canonicalUrl}
            variant="secondary"
          >
            View Full Details
          </Button>
          <Button
            data-testid="miniPIP-AITP-button"
            variant="Primary"
            fullWidth
            onClick={handleSubmit}
            type="submit"
          >
            Add to {addToType}
          </Button>
        </div>
      </DrawerFooter>
    </>
  );
};

MiniPIPBase.displayName = 'MiniPIPBase';

MiniPIPBase.propTypes = {
  itemId: PropTypes.string.isRequired,
  updateList: PropTypes.func
};

MiniPIPBase.defaultProps = {
  updateList: () => { }
};

MiniPIPBase.dataModel = extend(
  {
    product: params({
      itemId: string().isRequired(),
      dataSource: string()
    }).shape({
      itemId: string(),
      dataSources: string(),
      identifiers: shape({
        brandName: string(),
        productLabel: string(),
        storeSkuNumber: string()
      }),
      media: shape({
        images: arrayOf(shape({
          url: string(),
          type: string(),
          subType: string()
        }))
      }),
      reviews: shape({
        ratingsReviews: shape({
          averageRating: string(),
          totalReviews: string()
        })
      })
    })
  },
  Price,
  SuperSku,
  SalientPoints
);
