import React from 'react';
import * as pt from 'prop-types';
import {
  params, string, shape, arrayOf, bool, useDataModel
} from '@thd-nucleus/data-sources';
import { Typography, List, SkeletonLine } from '@one-thd/sui-atomic-components';
import { useHidePoints } from '../hooks/useHidePoints';
import { SalientListItem } from './SalientListItem';

const SalientPoints = (props) => {
  const {
    children, fallbackToDescription, hideIfMultiVariant, itemId, max, title
  } = props;
  const { data, loading, error } = useDataModel('product', { variables: { itemId } });
  const isSuperSku = !!data?.product?.identifiers?.isSuperSku;
  const parentId = data?.product?.identifiers?.parentId;

  const superSkuResponse = useDataModel('metadata', {
    variables: { parentId },
    ssr: false,
    skip: !parentId || !isSuperSku || !hideIfMultiVariant
  });
  const shouldHidePoints = useHidePoints(superSkuResponse);
  const hidePoints = hideIfMultiVariant ? shouldHidePoints : false;

  const { product } = data || {};
  const { details } = product || {};

  const { description, highlights } = details || {};
  const points = (highlights || []).slice(0, max);
  const useFallback = !points.length && fallbackToDescription && description;

  if ((!useFallback && !points.length && !data) || hidePoints) {
    return null;
  }
  /* istanbul ignore next */
  if (loading && !details) {
    return (
      <div data-component="SalientPointsLoadingPlaceholder">
        <SkeletonLine
          variant="multi"
          numberOfLines={3}
          fullWidth
        />
      </div>
    );
  }

  return (
    <>
      {((error && !data) || !data) && null}
      <div data-component="SalientPoints" data-testid="SalientPoints">
        {title && (
          <Typography variant="body-lg" weight="bold">
            <div
              className="sui-mb-4"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          </Typography>
        )}
        {useFallback && (
          <Typography variant="body-base">
            {description}
          </Typography>
        )}
        <List className="sui-text-base sui-list-disc sui-list-inside">
          <>
            {points.map((point, index) => {
              return <SalientListItem key={index} point={point} />;
            })}
          </>
          {children}
        </List>
      </div>

    </>

  );
};

SalientPoints.displayName = 'SalientPoints';

SalientPoints.propTypes = {
  // Additional salient points apart from productHighlights
  children: pt.node,
  // uses the description as a fallback value if no highlights
  fallbackToDescription: pt.bool,
  // Check whether to hide salient points if on desktop PEP page
  hideIfMultiVariant: pt.bool,
  // An Item that has product highlights
  itemId: pt.string.isRequired,
  // Limit the number of salient bullet points displayed
  max: pt.number,
  // list title
  title: pt.string
};

SalientPoints.defaultProps = {
  children: null,
  fallbackToDescription: false,
  hideIfMultiVariant: false,
  max: 3,
  title: null
};

SalientPoints.dataModel = {
  product: params({ itemId: string().isRequired() }).shape({
    itemId: string(),
    dataSources: string(),
    identifiers: shape({
      isSuperSku: bool(),
      parentId: string(),
    }),
    details: shape({
      description: string(),
      highlights: arrayOf(string())
    })
  }),
  metadata: params({ parentId: string().isRequired() }).shape({
    attributes: arrayOf(shape({
      attributeValues: arrayOf(shape({
        value: string()
      }))
    }))
  })
};

export { SalientPoints };
