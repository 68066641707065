const useHidePoints = (superSkuResponse) => {
  if (!superSkuResponse?.data) return false;
  const superSkuData = superSkuResponse?.data;
  const metadata = superSkuData?.metadata;
  const attributes = (metadata && metadata.attributes) ? metadata.attributes : [];
  return attributes?.filter((attribute) => {
    return attribute?.attributeValues && attribute.attributeValues?.length > 1;
  }).length > 1;
};

export { useHidePoints };